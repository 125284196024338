import React, { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import {
  StyledDiscoverHiveTechButton,
  StyledDiscoverHiveTechButtonContainer,
} from "./style";

export default ({
  label,
  file,
}) => {
  const { languages } = useContext(DataContext);
  const currentLanguage = languages.filter( (language) => language.isCurrent);
  const currentLanguageCode = currentLanguage ? currentLanguage[0].label : "en";

  return (
    <StyledDiscoverHiveTechButtonContainer>
      <StyledDiscoverHiveTechButton href={`/documents/${currentLanguageCode}/${file}`} download>
        {label}
      </StyledDiscoverHiveTechButton>
    </StyledDiscoverHiveTechButtonContainer>
  );
};
